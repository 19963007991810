import React from 'react';
import './Marker.css';
import logo from "../../assets/gado_chan.png";
import {makeStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 2),
    },
    heroContent: {
        backgroundColor: "rgba(0, 0, 0,.8)",
        padding: theme.spacing(8, 0, 6),
    },
}));
export default function Marker (props) {
    const classes = useStyles();
    const { color, id } = props;
    return (
        <div>
            <Paper className="gm-style">
                <div className="view-link">
                    <p>多国籍バルGadogado</p>
                    <p>
                        <a target="_blank" href="https://www.google.com/maps/place/%E5%A4%9A%E5%9B%BD%E7%B1%8D%E3%83%90%E3%83%ABGadogado/@36.0358821,138.0986851,15z/data=!4m5!3m4!1s0x601c55bc80ab5657:0x18820e201a679c7f!8m2!3d36.0418744!4d138.1048574?hl=ja">
                            <span>Googleマップで見る</span>
                        </a>
                    </p>
                </div>
            </Paper>
            <div
                className="pin bounce"
                style={{ backgroundColor: color, cursor: 'pointer' }}
            >
                <img src={logo} className="pin-image" alt="ガドガド" />
            </div>
        </div>
    );
};
