import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Top from "../top";
import Copyright from "../copyright";
import React, {useState} from "react";
import {Reveal} from "react-genie";
import {Link, makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import logo from "../../assets/gadogado_logo.png";
import gado from "../../assets/gado_chan.png";
import Grid from "@material-ui/core/Grid";
import Lightbox from "react-image-lightbox";
import LazyLoad from "react-lazyload";
import HistoryIcon from "@material-ui/icons/History";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "rgba(164,143,124, .9)",
        padding: theme.spacing(8, 0, 6),
    },
    imageBox: {
        width: "100%",
        display: "inline-block",
        position: "relative"
    },
    menuPickUpImage: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        [theme.breakpoints.up('md')]: {
            height: "550px"
        },
    },
    backNumber: {
        color: "#000",
    },
}));
export default function OldMenuGallery(props) {
    const {spMatches} = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [oldMenuOpen, setOldMenuOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [oldPhotoIndex, setOldPhotoIndex] = useState(0);
    const handleClickImage = (props) => {
        setPhotoIndex(props);
        setOpen(true);
    };
    const handleClickOldMenuOpen = (props) => {
        setOldPhotoIndex(props);
        setOldMenuOpen(true);
    };
    const oldImages = [
        '/images/menu/old/dinner/3.png',
        '/images/menu/old/dinner/4.png',
        '/images/menu/old/dinner/5.png',
        '/images/menu/old/dinner/6.png',
        '/images/menu/old/dinner/7.png',
        '/images/menu/old/dinner/8.png',
        '/images/menu/old/dinner/9.png',
        '/images/menu/old/takeout_half.jpg',
        '/images/menu/old/lunch_1.png',
        '/images/menu/old/lunch_2.png',
        '/images/menu/old/dinner_1.png',
        '/images/menu/old/dinner_2.png',
        '/images/menu/old/dinner_3.png',
        '/images/menu/old/dinner_4.png',
        '/images/menu/old/dinner_5.png',
        '/images/menu/old/course_1.jpg',
        '/images/menu/old/course_2.jpg'
    ];

    return (
        <div className={classes.root}>
            <Reveal>
                {oldMenuOpen && (
                    <Lightbox
                        mainSrc={oldImages[oldPhotoIndex]}
                        nextSrc={oldImages[(oldPhotoIndex + 1) % oldImages.length]}
                        prevSrc={oldImages[(oldPhotoIndex + oldImages.length - 1) % oldImages.length]}
                        onCloseRequest={() => setOldMenuOpen(false)}
                        onMovePrevRequest={() =>
                            setOldPhotoIndex((oldPhotoIndex + oldImages.length - 1) % oldImages.length)
                        }
                        onMoveNextRequest={() =>
                            setOldPhotoIndex((oldPhotoIndex + 1) % oldImages.length)
                        }
                    />
                )}
                <Box fontSize={spMatches ? "h2.fontSize": "h2.fontSize"}>
                    <Typography variant="h3" component="h2" className="text-black text-bold en">
                        OLD MENU
                    </Typography>
                    <Typography variant={spMatches ? "subtitle1" : "h5"} component="h2" className="text-black text-bold en" >
                        過去のメニューブック置き場
                    </Typography>
                </Box>
            </Reveal>
            {spMatches && (
                <Reveal delay={200} className="balloon5">
                    <div className="faceicon">
                        <img src={gado} className={spMatches ? "gadochan-sp" : "gadochan"}
                             alt="ガドガドのキャラクター" />
                    </div>
                    <div className="chatting">
                        <div className="says">
                            <Link
                                // className={classes.backNumber}
                                onClick={()=> {handleClickOldMenuOpen(0)}}>
                                <p>👆ココを押してね 👆</p>
                            </Link>
                        </div>
                    </div>
                    <div className="chatting">
                        <div className="says">
                            <p>スタッフさんが描いてくださった、個性的なメニュー表です。</p>
                        </div>
                    </div>
                </Reveal>
            )}
        </div>
    )
}
