import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Top from "../top";
import Copyright from "../copyright";
import React from "react";
import {Reveal} from "react-genie";
import {Link, makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import logo from "../../assets/gadogado_logo.png";
import gado from "../../assets/gado_chan.png";
import Grid from "@material-ui/core/Grid";
import LazyLoad from "react-lazyload";
import origin from "../../assets/origin.jpg";
import FlagIcon from "../FlagIcon";
import Sp from "./sp";
import Pc from "./pc";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "rgba(164,143,124, .9)",
        padding: theme.spacing(8, 0, 6)
    },
    imageBox: {
        width: "100%",
        height: "80px",
        display: "inline-block",
        position: "relative"
    },
    menuImage: {
        width: "100%",
        height: "150px",
        objectFit: "cover",
        [theme.breakpoints.up('md')]: {
            height: "250px"
        },
    },
    menuPickUpImage: {
        width: "100%",
        height: "200px",
        objectFit: "cover",
        [theme.breakpoints.up('md')]: {
            height: "550px"
        },
    },
    flag: {
        position: "absolute",
        top: "0",
        left: "0"
    },
    originFlagPickUp: {
        width: "42px",
        height: "32px",
        objectFit: "cover",
        [theme.breakpoints.up('md')]: {
            width: "85px",
            height: "64px"
        },
    },
    originFlag: {
        width: "42px",
        height: "32px",
        objectFit: "cover",
        [theme.breakpoints.up('md')]: {
            width: "85px",
            height: "64px"
        },
    }
}));
export default function Dessert(props) {
    const {spMatches} = props;
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Reveal>
                <Box fontSize={spMatches ? "h2.fontSize": "h2.fontSize"}>
                    <Typography variant="h3" component="h2" className="text-black text-bold en">
                        DESSERT
                    </Typography>
                    <Typography variant={spMatches ? "subtitle1" : "h5"} component="h2" className="text-black text-bold en" >
                        デザート
                    </Typography>
                </Box>
                <Box mt={1} py={spMatches ? 3 : 0} px={spMatches ? 3 : 0}>
                    {!spMatches && (
                        <Pc/>
                    )}
                    {spMatches && (
                        <Sp/>
                    )}
                </Box>
                {/*<Box style={{width: "80%", margin: "0 auto"}}>*/}
                {/*    <Link*/}
                {/*        href="https://tabelog.com/nagano/A2004/A200404/20018874/dtlmenu/"*/}
                {/*        target="_blank"*/}
                {/*        className="tabelog-button"*/}
                {/*    >*/}
                {/*        <Typography variant="subtitle1"　className="text-bold en" >*/}
                {/*            <span style={{ marginLeft: '1rem'}}>その他のメニューは食べログへ。</span>*/}
                {/*        </Typography>*/}
                {/*    </Link>*/}
                {/*</Box>*/}
            </Reveal>
            <Reveal delay={200}>
                <img src={gado} className={spMatches ? "gadochan-sp" : "gadochan"}
                     alt="ガドガドのキャラクター" />
            </Reveal>
            {/*<Box fontSize={spMatches ? "h2.fontSize": "h2.fontSize"}>*/}
            {/*    <Typography variant="h6" component="h2" className="text-black text-bold en">*/}
            {/*        メニューが多くて載せ切れません！*/}
            {/*    </Typography>*/}
            {/*    <Typography variant="h6" component="h2" className="text-black text-bold en">*/}
            {/*        お気軽にお問い合わせください。*/}
            {/*    </Typography>*/}
            {/*</Box>*/}
        </div>
    )
}
