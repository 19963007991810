
export const HOT_MENU = "🌶";

export const DISCRIPTIOM_ITEMS = [
    {
        "code": "vn",
        "name": "ベトナム料理",
        "image": ""
    },
    {
        "code": "th",
        "name": "タイ料理",
        "image": ""
    },
    {
        "code": "in",
        "name": "インド料理",
        "image": ""
    },
    {
        "code": "id",
        "name": "インドネシア料理",
        "image": ""
    },
    {
        "code": "cn",
        "name": "中華料理",
        "image": ""
    },
    {
        "code": "tw",
        "name": "台湾料理",
        "image": ""
    },
    {
        "code": "sg",
        "name": "シンガポール料理",
        "image": ""
    },
    {
        "code": "ph",
        "name": "フィリピン料理",
        "image": ""
    },
    {
        "code": "og",
        "name": "オリジナル・創作料理",
        "image": "origin.jpg"
    }
]


