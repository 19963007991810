import Box from "@material-ui/core/Box";
import React, {useState} from "react";
import {Reveal} from "react-genie";
import {Link, makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Sp from "./sp";
import HistoryIcon from "@material-ui/icons/History";
import SelectBackNumberDialog from "./backnumber/modal.select";
import BackNumberImage from "./backnumber/image";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(8, 0, 6)
    },
    newsContent: {
//        margin: "1rem",
    },
    rootColor2: {
        backgroundColor: "rgba(0,0,0, .9)",
    },
    backNumberButton: {
        display: "block",
        width: "80%",
        backgroundColor: "rgba(255, 255, 255, .8)",
        color: "#000",
        padding: "0.5rem",
        margin: "0 auto",
    },
}));

export default function MenuList(props) {
    const {spMatches} = props;
    const [open, setOpen] = useState(false);
    const [backNumberPage, setBackNumberPage] = useState(0);
    const [backNumberIndex, setBackNumberIndex] = useState(null);
    const classes = useStyles();
    function handleClickOpen () {
        setOpen(true);
    }
    function handleClose () {
        setBackNumberPage(0);
        setBackNumberIndex(null);
        setOpen(false);
    }
    function handleShowBackNumber (index, page) {
        setBackNumberPage(page);
        setBackNumberIndex(index);
        setOpen(false);
    }
    return (
        <div className={classes.root}>
            <Reveal>
                <Box my={1} fontSize={spMatches ? "h2.fontSize": "h2.fontSize"} className={classes.rootColor2}>
                    {spMatches && (
                        <>
                            <Typography variant="h5" component="h2" className="text-black text-bold en section-title" align="center">
                                限定メニュー
                            </Typography>
                    {/*        <Box my={3} className={classes.rootColor2}>*/}
                    {/*            <Typography variant="subtitle1" component="h2" className="text-white text-bold en" >*/}
                    {/*                ガドガド 2023年🌸春〜夏🏖️の限定メニュー*/}
                    {/*            </Typography>*/}
                    {/*            /!*<Typography variant="subtitle1" component="h2" className="text-white text-bold en" >*!/*/}
                    {/*            /!*    & 7周年記念メニュー！*!/*/}
                    {/*            /!*</Typography>*!/*/}
                    {/*            <Typography variant="subtitle2" component="p" className="text-white text-bold en" >*/}
                    {/*                （画像をタップすると、拡大&スライドできます。）*/}
                    {/*            </Typography>*/}
                    {/*        </Box>*/}
                        </>
                    )}
                    {/*<Box py={spMatches ? 3 : 0} px={spMatches ? 3 : 0}>*/}
                    {/*    {spMatches && (*/}
                    {/*        <Sp/>*/}
                    {/*    )}*/}
                    {/*</Box>*/}
                    <Box my={2} py={2} className={classes.rootColor2}>
                        <Link
                            className={classes.backNumberButton}
                            onClick={()=> {handleClickOpen()}}>
                            <Typography variant="subtitle1"　className="text-bold en" >
                                <HistoryIcon />
                                <span style={{ marginLeft: '1rem'}}>過去の限定メニューを見る！</span>
                            </Typography>
                        </Link>
                        {open && (
                            <SelectBackNumberDialog handleShowBackNumber={handleShowBackNumber} handleClose={handleClose}/>
                        )}
                        {backNumberIndex !== null && (
                            <BackNumberImage handleClose={handleClose} backNumberIndex={backNumberIndex} backNumberPage={backNumberPage}/>
                        )}
                    </Box>
                </Box>
            </Reveal>
            <Box className="relativeBox">
                <div className="scroll-prompt"><span></span></div>
            </Box>
        </div>
    )
}
