import React from 'react';
import {makeStyles} from "@material-ui/core";
import {Reveal} from 'react-genie';
import head from "../../assets/head_image.png";
import logo from "../../assets/gadogado_logo.png";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PhoneIcon from "@material-ui/icons/Phone";
import Link from "@material-ui/core/Link";
import classNames from "classnames";
import gado from "../../assets/gado_chan.png";
import MyDivider from "../MyDivider";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "rgba(0,0,0, 0.8)",
    },
    topImageBox: {
        position: "relative",
        "& h1": {
            position: "absolute",
            top: "20vh",
            left: "0",
            right: "0",
            // sp
            [theme.breakpoints.down('sm')]: {
                top: "15vh",
            }
        }
    },
    orientationHandle: {
    },
}));

export default function Top(props) {
    const {spMatches} = props;
    const classes = useStyles();
    const linkButton = classNames("text-black");
    return (
        <Reveal className={classes.root}>
            <div className={classes.topImageBox}>
                <Reveal>
                    <img src={head} className={spMatches ? "App-head-sp" : "App-head"}
                         alt="多国籍バル・ガドガド ヘッダー" />
                </Reveal>
                <Box my={1}>
                    <Typography component="h1" color="textPrimary" >
                        <Box fontSize={spMatches ? "subtitle1.fontSize": "h6.fontSize"} fontWeight="fontWeightBold" className="contents-title-black">
                            多国籍バル ガドガド
                        </Box>
                    </Typography>
                    <h1>
                        <img src={logo} className={spMatches ? "App-logo-sp" : "App-logo"}
                             alt="多国籍バル・ガドガドの" />
                    </h1>
                </Box>
            </div>
            <Box mt={20} className="relativeBox" mx={spMatches ? 5 : 20}>
                <Box fontSize={spMatches ? "h6.fontSize": "h6.fontSize"}>
                    <div className={classes.orientationHandle}>
                        <Typography component="p" className="text-black text-bold" align="center">
                            諏訪市高島2丁目1201-44
                        </Typography>
                        <Typography component="p" className="text-black text-bold" align="center">
                            諏訪湖ホワイトモール1F
                            <span style={{marginLeft: "1rem"}}>定休日:月曜日</span>
                        </Typography>
                        <Box mt={2}>
                            <Typography component="p" variant="h5" className="text-black text-bold" align="center">
                                <Link href="tel:0266755324"
                                      className={linkButton} >
                                    <PhoneIcon/>
                                    <span style={{marginLeft:"1rem"}}>0266-75-5324</span>
                                </Link>
                            </Typography>
                        </Box>
                        <Box mt={2}>
                            <Typography component="p" className="text-black text-bold" align="center">
                                <span className="en">- Lunch -</span><br/>
                                <span className="en">火~土:</span>11:30-14:30(
                                <span className="en">L.O:</span>14:00)<br/>
                                <span className="en">日:</span>11:30-15:00(
                                <span className="en">L.O:</span>14:30)
                            </Typography>
                        </Box>
                        {/*<Box mt={2}>*/}
                        {/*    <Typography component="p" className="text-black text-bold" align="center">*/}
                        {/*        <span className="en">- Dinner -</span><br/>*/}
                        {/*        <span className="en">火~木,日:</span>18:00-23:00(*/}
                        {/*        <span className="en">L.O:</span>22:30)<br/>*/}
                        {/*        <span className="en">土:</span>18:00-23:00(*/}
                        {/*        <span className="en">L.O:</span>22:30)*/}
                        {/*    </Typography>*/}
                        {/*</Box>*/}
                        <Box mt={2}>
                            <Typography component="p" className="text-black text-bold" align="center">
                                <span className="en">- Dinner -</span><br/>
                                <span className="en">火~日:</span>18:00-23:00(
                                <span className="en">L.O:</span>22:30)<br/>
                            </Typography>
                        </Box>
                    </div>
                </Box>
            </Box>
            <Box className="relativeBox">
                <div className="scroll-prompt"><span></span></div>
            </Box>
            {spMatches && (
                <Reveal>
                    <img src={head} className={spMatches ? "App-head-sp rotate" : "App-head rotate"}
                         alt="多国籍バル・ガドガド ヘッダー" />
                </Reveal>
            )}
            <MyDivider/>
            <Box mt={spMatches ? 5 : 20} className={spMatches ? "relativeBox top-box-sp" : "relativeBox top-box"}>
                <Box fontSize={spMatches ? "body2.fontSize": "body2.fontSize"}>
                    <Typography variant="h3" component="h2"  className="text-black text-bold sp-about-gado en" align="center">
                        ガドガドとは...
                    </Typography>
                    <Typography component="p" className="text-black text-bold" align="left">
                        インドネシア語で「ごちゃまぜ」という意味。
                    </Typography>
                    <Typography component="p" className="text-black text-bold" align="left">
                        タイ料理、ベトナム料理、インド料理、インドネシア料理等々、アジア料理を中心に、色々な国のお料理を、
                        お店はもちろん、お家へ持ち帰っても、旅行気分でお楽しみいただけます。
                    </Typography>
                </Box>
                <Box className="relativeBox">
                    <div className="scroll-prompt"><span></span></div>
                </Box>
            </Box>
            <Box mt={spMatches ? 5 : 20} className={spMatches ? "relativeBox top-box-sp" : "relativeBox top-box"}>
                <Box fontSize={spMatches ? "body2.fontSize": "body2.fontSize"}>
                    <Typography component="p" className="text-black text-bold" align="left">
                        諏訪市でランチといえばガドガド。
                    </Typography>
                    <Typography component="p" className="text-black text-bold" align="left">
                        諏訪市でディナーといえばガドガド。
                    </Typography>
                    <Typography component="p" className="text-black text-bold" align="left">
                        エスニックといえばガドガド。激辛といえばガドガド。爆盛メニューといえば...と、様々な顔を持つ、遊び心溢れるお店です。
                    </Typography>
                    <Typography component="p" className="text-black text-bold" align="left">
                        各種パーティーや、テイクアウトも承っております。
                    </Typography>
                </Box>
                <Reveal delay={200}>
                    <img src={gado} className={spMatches ? "gadochan-sp" : "gadochan"}
                         alt="ガドガドのキャラクター" />
                </Reveal>
                <Box className="relativeBox">
                    <div className="scroll-prompt"><span></span></div>
                </Box>
            </Box>
        </Reveal>
    )
}
