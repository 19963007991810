import {Reveal} from "react-genie";
import React from "react";
import {Button, IconButton, Link, makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {FacebookProvider, Page} from 'react-facebook';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import Feed from "react-instagram-authless-feed"
import GadoThree from "../menu/gadothree";
import {ErrorBoundary} from "react-error-boundary";
import LazyLoad from "react-lazyload";
import gado from "../../assets/gado_chan.png";
import {DISCRIPTIOM_ITEMS} from "../const";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import Pc from "../dessert/pc";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "rgba(164,143,124, .9)",
    },
    descriptionTable: {
        backgroundColor: "rgba(164,143,124, 1)",
    },
    originFlag: {
        width: "40px",
        height: "30px",
        objectFit: "cover",
        [theme.breakpoints.up('md')]: {
            width: "55px",
            height: "40px"
        },
    },
    newsContent: {
        // margin: "1rem",
    },
    fbButton: {
        display: "block",
        width: "80%",
        backgroundColor: "#1693F3",
        color: "#fff",
        padding: "0.5rem",
        margin: "0 auto",
    },
    ytButton: {
        display: "block",
        width: "80%",
        backgroundColor: "#FC0100",
        color: "#fff",
        padding: "0.5rem",
        margin: "0 auto",
    },
    rootColor2: {
        backgroundColor: "rgba(0,0,0, .9)",
    },
    instaImageBox: {
        width: "100%",
        display: "inline-block",
        position: "relative"
    },
    instaImage: {
        width: "100%",
        objectFit: "cover",
    },
    osechiBox: {
        background: "linear-gradient(45deg, #B67B03 0%, #DAAF08 45%, #FEE9A0 70%, #DAAF08 85%, #B67B03 90% 100%)"
    }
}));
//
const instagramEmbeds = [
    //firebase projects:list
    // yarn build && firebase deploy --only hosting
    // {
    //     'image': '/images/instagram/9th_anniversary.jpg',
    //     'url': 'https://www.instagram.com/p/C5uPp7RB_wv/?img_index=1',
    // },
    {
        'image': '/images/instagram/1.jpg',
        'url': 'https://www.instagram.com/p/C_J0JuyhVdS/?img_index=1',
    },
    {
        'image': '/images/instagram/2.jpg',
        'url': 'https://www.instagram.com/p/C95tgmpqekg/',
    },
]

export function News (props) {
    const appID = '580565926022455';
    const clientToken = '0281296a7961d3cea1a6a88cdbae3a48';
    const clientAccessToken = `${appID}|${clientToken}`
    const {spMatches} = props;
    const classes = useStyles();
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const formattedDate = `${year}${month}${day}`;
    const theme = useTheme();
    const mySpMatches = useMediaQuery(theme.breakpoints.down('sm'));
    // エラー時のフォールバック用のコンポーネント
    const ErrorFallback = ({ error, resetErrorBoundary }) => {
        return (
            <div role="alert">
                <p>Error!</p>
                <button onClick={resetErrorBoundary}>Try again.</button>
            </div>
        );
    };
    return (
        <div className={classes.root}>
            {spMatches && (
                <div>
                    <Box my={1} fontSize={spMatches ? "h2.fontSize": "h2.fontSize"} className={classes.newsContent}>
                        <Typography variant="h3" component="h2" className="text-black text-bold en section-title">
                            What's new
                        </Typography>
                        <Box my={3}>
                            <Typography variant={spMatches ? "subtitle1" : "h5"} component="h2" className="text-black text-bold en" >
                                最新情報はfacebookをチェック😋
                            </Typography>
                        </Box>
                    </Box>
                    <Box my={1}>
                        <Grid container spacing={2} >
                            <Grid item xs={12} md={12}>
                                <FacebookProvider appId="580565926022455">
                                    <Page
                                        href="https://www.facebook.com/gadogadotsa"
                                        tabs="timeline" />
                                </FacebookProvider>
                            </Grid>
                        </Grid>
                        <Box my={2}>
                            <Link
                                href="https://www.facebook.com/gadogadotsa"
                                target="_blank"
                                className={classes.fbButton}
                            >
                                <Typography variant="subtitle1"　className="text-bold en" >
                                    <FacebookIcon />
                                    <span style={{ marginLeft: '1rem'}}>facebookをもっと見る</span>
                                </Typography>
                            </Link>
                        </Box>
                        <GadoThree/>
                    </Box>
                    <Box my={1} fontSize={spMatches ? "h2.fontSize": "h2.fontSize"} className={classes.rootColor2}>
                        <Typography variant="h3" component="h2" className="text-bold en section-title">
                            YouTube
                        </Typography>
                        <Box mt={3}>
                            <Typography variant={spMatches ? "subtitle1" : "h5"} component="h2" className="text-white text-bold en" >
                                チャンネル登録者様限定!<br/>プレミアムメニューあります🍚
                            </Typography>
                        </Box>
                    </Box>
                    <Box my={1} className={classes.rootColor2}>
                        <Grid container spacing={2} >
                            <Grid item xs={false} sm={false} md={false}/>
                            <Grid item xs={12} md={12} className={classes.flexVideo}>
                                <iframe
                                    title={"sp-youtube"}
                                    width={spMatches ? "80%": "100%"} height="315"
                                    src="https://www.youtube.com/embed/?list=UUKw04o43EvH8yvMBc_HE-tg" frameBorder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen>
                                </iframe>
                            </Grid>
                            <Grid item xs={false} sm={false} md={false}/>
                        </Grid>
                        <Box my={2} className={classes.rootColor2}>
                            <Link
                                href="https://www.youtube.com/channel/UCKw04o43EvH8yvMBc_HE-tg"
                                target="_blank"
                                className={classes.ytButton}
                            >
                                <Typography variant="subtitle1"　className="text-bold en" >
                                    <YouTubeIcon />
                                    <span style={{ marginLeft: '1rem'}}>ガドチャンネルをもっと見る</span>
                                </Typography>
                            </Link>
                        </Box>
                        <GadoThree/>
                    </Box>
                    <Box my={1} fontSize={spMatches ? "h2.fontSize": "h2.fontSize"} className={classes.newsContent}>
                        <Typography variant="h3" component="h2" className="text-black text-bold en section-title">
                            instagram
                        </Typography>
                        <Box mt={3}>
                            <Typography variant={spMatches ? "subtitle1" : "h5"} component="h2" className="text-black text-bold en" >
                                フォローすると、いいことあるかも・・・
                            </Typography>
                        </Box>
                    </Box>
                    <Box my={1}>
                        <Grid container spacing={2} >
                            <Grid item xs={false} sm={false} md={false}/>
                            <Grid item xs={12} md={12}>
                                <Box p={2} pt={0}>
                                    {/* スタッフ募集 */}
                                    <Link
                                        href="https://www.instagram.com/p/CMhOPJ_BYef/"
                                        target="_blank"
                                    >
                                        <div className={classes.instaImageBox}>
                                            <LazyLoad>
                                                <img src="/images/staff_kyuubo1.jpg"
                                                     alt="スタッフ募集" className={classes.instaImage} />
                                            </LazyLoad>
                                        </div>
                                    </Link>
                                    <Reveal delay={200} className="balloon5" style={{marginBottom: '1rem'}}>
                                        <div className="faceicon-2">
                                            <img src={gado} className={spMatches ? "gadochan-sp" : "gadochan"}
                                                 alt="ガドガドのキャラクター" />
                                        </div>
                                        <div className="chatting">
                                            <div className="says">
                                                <p>スタッフさん大募集❗</p>
                                            </div>
                                        </div>
                                    </Reveal>
                                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                                        {instagramEmbeds.map((item, index) => (
                                            <Box key={index} pt={4}>
                                                <Link
                                                    href={item.url}
                                                    target="_blank"
                                                >
                                                    <div className={classes.instaImageBox}>
                                                        <LazyLoad>
                                                            <img src={item.image + "?v=" + formattedDate} className={classes.instaImage} alt="ガドガドのインスタ記事"/>
                                                        </LazyLoad>
                                                    </div>
                                                </Link>
                                                {/*{index === 0 && (*/}
                                                {/*    <Box className={classes.osechiBox}>*/}
                                                {/*        <Typography style={{ marginTop: '1rem', paddingTop: '1rem' }}*/}
                                                {/*                    variant={spMatches ? "h6" : "h5"} component="h2" className="text-black text-bold en" >*/}
                                                {/*            🎍エスニックおせちのご紹介🎍*/}
                                                {/*        </Typography>*/}

                                                {/*        <Table className={classes.table}>*/}
                                                {/*            <TableBody>*/}
                                                {/*                <TableRow>*/}
                                                {/*                    <TableCell style={{ width: 90 }} component="td" align="center" className="text-white">*/}
                                                {/*                        <Typography component="p" className="text-black text-bold" align="center">*/}
                                                {/*                            ＜一の重＞*/}
                                                {/*                        </Typography>*/}
                                                {/*                        <img src="/images/instagram/osechi_1.jpg"*/}
                                                {/*                             alt="ガドガドおせち一の重" style={{ width: '100%' }} />*/}
                                                {/*                    </TableCell>*/}
                                                {/*                    <TableCell  component="td" align="center" className="text-white">*/}
                                                {/*                        {!mySpMatches && (*/}
                                                {/*                            <Typography component="p" className="text-black text-bold" align="left">*/}
                                                {/*                                ローストビーフ、タンドリーチキン、燻製ハーブ焼豚、ラムチョップ香草焼き、スペアリブコンフィ*/}
                                                {/*                            </Typography>*/}
                                                {/*                        )}*/}
                                                {/*                        {mySpMatches && (*/}
                                                {/*                            <Typography component="p" className="text-black text-bold" align="left">*/}
                                                {/*                                ローストビーフ<br/>タンドリーチキン<br/>燻製ハーブ焼豚<br/>ラムチョップ香草焼き<br/>スペアリブコンフィ*/}
                                                {/*                            </Typography>*/}
                                                {/*                        )}*/}
                                                {/*                    </TableCell>*/}
                                                {/*                </TableRow>*/}
                                                {/*                <TableRow>*/}
                                                {/*                    <TableCell style={{ width: 90 }} component="td" align="center" className="text-white">*/}
                                                {/*                        <Typography component="p" className="text-black text-bold" align="center">*/}
                                                {/*                            ＜二の重＞*/}
                                                {/*                        </Typography>*/}
                                                {/*                        <img src="/images/instagram/osechi_2.jpg"*/}
                                                {/*                             alt="ガドガドおせち二の重" style={{ width: '100%' }} />*/}
                                                {/*                    </TableCell>*/}
                                                {/*                    <TableCell  component="td" align="center" className="text-white">*/}
                                                {/*                        {!mySpMatches && (*/}
                                                {/*                            <Typography component="p" className="text-black text-bold" align="left">*/}
                                                {/*                                ソフトシェル唐揚げ、有頭海老のガパオ炒め、海老マヨネーズ、スモークサーモン巻き、牡蠣のオイル漬け*/}
                                                {/*                            </Typography>*/}
                                                {/*                        )}*/}
                                                {/*                        {mySpMatches && (*/}
                                                {/*                            <Typography component="p" className="text-black text-bold" align="left">*/}
                                                {/*                                ソフトシェル唐揚げ<br/>有頭海老のガパオ炒め<br/>海老マヨネーズ<br/>スモークサーモン巻き<br/>牡蠣のオイル漬け*/}
                                                {/*                            </Typography>*/}
                                                {/*                        )}*/}
                                                {/*                    </TableCell>*/}
                                                {/*                </TableRow>*/}
                                                {/*                <TableRow>*/}
                                                {/*                    <TableCell style={{ width: 90 }} component="td" align="center" className="text-white">*/}
                                                {/*                        <Typography component="p" className="text-black text-bold" align="center">*/}
                                                {/*                            ＜三の重＞*/}
                                                {/*                        </Typography>*/}
                                                {/*                        <img src="/images/instagram/osechi_3.jpg"*/}
                                                {/*                             alt="ガドガドおせち三の重" style={{ width: '100%' }} />*/}
                                                {/*                    </TableCell>*/}
                                                {/*                    <TableCell  component="td" align="center" className="text-white">*/}
                                                {/*                        {!mySpMatches && (*/}
                                                {/*                            <Typography component="p" className="text-black text-bold" align="left">*/}
                                                {/*                                サーロインステーキ、燻製味玉子結び串、エスニック炊き寄せ、チャージョー、パイ包み焼き*/}
                                                {/*                            </Typography>*/}
                                                {/*                        )}*/}
                                                {/*                        {mySpMatches && (*/}
                                                {/*                            <Typography component="p" className="text-black text-bold" align="left">*/}
                                                {/*                                サーロインステーキ<br/>燻製味玉子結び串<br/>エスニック炊き寄せ<br/>チャージョー<br/>パイ包み焼き*/}
                                                {/*                            </Typography>*/}
                                                {/*                        )}*/}
                                                {/*                    </TableCell>*/}
                                                {/*                </TableRow>*/}
                                                {/*            </TableBody>*/}
                                                {/*        </Table>*/}
                                                {/*        <Reveal delay={200} className="balloon5" style={{marginBottom: '1rem', paddingBottom: '5rem'}}>*/}
                                                {/*            <div className="faceicon-2">*/}
                                                {/*                <img src={gado} className={spMatches ? "gadochan-sp" : "gadochan"}*/}
                                                {/*                     alt="ガドガドのキャラクター" />*/}
                                                {/*            </div>*/}
                                                {/*            <div className="chatting">*/}
                                                {/*                <div className="says">*/}
                                                {/*                    <Link*/}
                                                {/*                        target="_blank"*/}
                                                {/*                        href="https://www.instagram.com/p/CzVXEJaNHlM/?img_index=1">*/}
                                                {/*                        <span>ご予約</span>*/}
                                                {/*                    </Link>*/}
                                                {/*                    <span>お待ちしております🙇</span>*/}
                                                {/*                </div>*/}
                                                {/*            </div>*/}
                                                {/*        </Reveal>*/}
                                                {/*    </Box>*/}
                                                {/*)}*/}
                                            </Box>
                                        ))}
                                        {/*<Feed userName="minorugadogado" limit={2} />*/}
                                    </ErrorBoundary>
                                </Box>
                            </Grid>
                            <Grid item xs={false} sm={false} md={false}/>
                        </Grid>
                        <Box mt={2}>
                            <Link
                                href="https://www.instagram.com/minorugadogado/"
                                target="_blank"
                                className={classes.fbButton}
                            >
                                <Typography variant="subtitle1"　className="text-bold en" >
                                    <InstagramIcon />
                                    <span style={{ marginLeft: '1rem'}}>@minorugadogadoをもっと見る</span>
                                </Typography>
                            </Link>
                        </Box>
                        <GadoThree/>
                    </Box>
                </div>
            )}
            {!spMatches && (
                <div>
                    <Box my={1} fontSize={spMatches ? "h2.fontSize": "h2.fontSize"} className={classes.newsContent}>
                        <Typography variant="h3" component="h2" className="section-title text-bold en">
                            What's new
                        </Typography>
                        <Box my={5}>
                            <Grid container spacing={2} >
                                <Grid item xs={6} md={6}>
                                    <Typography variant="h5" component="h2" className="text-black text-bold en" >
                                        - 最新情報はfacebookから -
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <Typography variant="h5" component="h2" className="text-black text-bold en">
                                        - YouTube -
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box my={1}>
                        <Grid container spacing={2} >
                            <Grid item xs={6} md={6} style={{borderRight: '1px solid #000'}}>
                                <FacebookProvider appId="580565926022455">
                                    <Page
                                        href="https://www.facebook.com/gadogadotsa"
                                        tabs="timeline" />
                                </FacebookProvider>
                                <Box my={2}>
                                    <Link
                                        href="https://www.facebook.com/gadogadotsa"
                                        target="_blank"
                                        className={classes.fbButton}
                                    >
                                        <Typography variant="subtitle1"　className="text-bold en" >
                                            <FacebookIcon />
                                            <span style={{ marginLeft: '1rem'}}>facebookをもっと見る</span>
                                        </Typography>
                                    </Link>
                                </Box>
                            </Grid>
                            <Grid item md={6} className={classes.flexVideo}>
                                <iframe
                                    width={spMatches ? "100%": "560"} height="500"
                                    src="https://www.youtube.com/embed/?list=UUKw04o43EvH8yvMBc_HE-tg" frameBorder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen>
                                </iframe>
                                <Box my={2}>
                                    <Link
                                        href="https://www.youtube.com/channel/UCKw04o43EvH8yvMBc_HE-tg"
                                        target="_blank"
                                        className={classes.ytButton}
                                    >
                                        <Typography variant="subtitle1"　className="text-bold en" >
                                            <YouTubeIcon />
                                            <span style={{ marginLeft: '1rem'}}>ガドチャンネルをもっと見る</span>
                                        </Typography>
                                    </Link>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box my={1}>
                        <Box my={1} fontSize={spMatches ? "h2.fontSize": "h2.fontSize"} className={classes.newsContent}>
                            <Grid container spacing={2} >
                                <Grid item xs={6} md={6}>
                                    <Typography variant="h5" component="h2" className="text-black text-bold en">
                                        - instagram -
                                    </Typography>
                                    <Box p={5}>
                                        <ErrorBoundary FallbackComponent={ErrorFallback}>
                                            <Feed userName="minorugadogado" className="Feed" classNameLoading="Loading" limit="2"/>
                                        </ErrorBoundary>
                                    </Box>
                                    <Box mt={2}>
                                        <Link
                                            href="https://www.instagram.com/minorugadogado/"
                                            target="_blank"
                                            className={classes.fbButton}
                                        >
                                            <Typography variant="subtitle1"　className="text-bold en" >
                                                <InstagramIcon />
                                                <span style={{ marginLeft: '1rem'}}>@minorugadogadoをもっと見る</span>
                                            </Typography>
                                        </Link>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </div>
            )}
        </div>
    )
}
