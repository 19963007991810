import Typography from "@material-ui/core/Typography";
import moment from "moment";
import React from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    footer: {
        backgroundColor: "rgba(0,0,0, 0.8)",
            color: theme.palette.common.white,
            fontFamily: "Dimbo!important",
            // sp
            [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 0),
        }
    }
}));
export default function Copyright(props) {
    const classes = useStyles();
    const {matches} = props;
    return (
        <footer className={classes.footer}>
            <Typography variant="subtitle1" color="inherit" align="center">
                {'© '}
                Gadogado
                {' '}
                {moment().format('YYYY')}
            </Typography>
        </footer>
    );
}