import React from 'react';
import Copyright from "../components/copyright";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Top from "../components/top";
import {makeStyles} from "@material-ui/core";
import MenuList from "../components/menu";
import AlaCarte from "../components/alacarte";
import Lunch from "../components/lunch";
import Dinner from "../components/dinner";
import Footer from "../components/footer";
import TakeOut from "../components/takeout";
import Bottom from "../components/bottom";
import Course from "../components/course";
import MyDivider from "../components/MyDivider";
import {IconDescription} from "../components/description";
import {News} from "../components/news";
import Dessert from "../components/dessert";
import Drink from "../components/drink";
import VeryHot from "../components/veryhot";
import VeryBig from "../components/verybig";
import Grid from "@material-ui/core/Grid";
import MeatAndFried from "../components/meatandfried";
import Sweets from "../components/sweets";
import Typography from "@material-ui/core/Typography";
import OldMenuGallery from "../components/riceandnoodle";
import Onegai from "../components/onegai";

const useStyles = makeStyles(theme => ({
    rowSpace: {
        minHeight: "80vh",
        // sp
        [theme.breakpoints.down('sm')]: {
            minHeight: "40vh",
        }
    },
    rowSpaceEnd: {
        minHeight: "90vh"
    },
    dividerWrapper: {
        backgroundColor: "rgba(164,143,124, .9)",
        paddingLeft: "5rem",
        paddingRight: "5rem",
        // sp
        [theme.breakpoints.down('sm')]: {
            paddingLeft: "3rem",
            paddingRight: "3rem"
        }
    },
}));


export default function IndexPage(props) {
    const {spMatches} = props;
    return (
        <Container style={{padding: "0"}}>
            <Box className={spMatches ? "App-header-sp" : "App-header"} id="top">
                <Top spMatches={true}/>
            </Box>
            <MyDivider/>
            <Box id="icon-description">
                <News spMatches={true}/>
            </Box>
            <Box id="icon-description">
                <MenuList spMatches={true}/>
            </Box>
            <MyDivider/>
            <Box id="icon-description">
                <IconDescription spMatches={true}/>
            </Box>
            {/*<MyDivider/>*/}
            {/*<Box id="dessert">*/}
            {/*    <Dessert spMatches={true}/>*/}
            {/*</Box>*/}

            <div>
                <Box>
                    <Typography variant="h5" component="h2" className="text-black text-bold en section-title" align="center">
                        メニュー
                    </Typography>
                </Box>
                <Box id="takeout">
                    <Onegai spMatches={true}/>
                    <TakeOut spMatches={true}/>
                </Box>
                <MyDivider/>
                <Box id="MeatAndFried">
                    <MeatAndFried spMatches={true}/>
                </Box>
                <MyDivider/>
                <Box id="AlaCarte">
                    <AlaCarte spMatches={true}/>
                </Box>
                <MyDivider/>
                <Box id="Sweets">
                    <Sweets spMatches={true}/>
                </Box>
                {/*<Box id="lunch">*/}
                {/*    <Lunch spMatches={true}/>*/}
                {/*</Box>*/}
                {/*<MyDivider/>*/}
                {/*<Box id="dinner">*/}
                {/*    <Dinner spMatches={true}/>*/}
                {/*</Box>*/}
                <MyDivider/>
                <Box id="drink">
                    <Drink spMatches={true}/>
                </Box>
                <MyDivider/>
                <Box id="course">
                    <Course spMatches={true}/>
                </Box>
                <MyDivider/>
                <Box id="veryhot">
                    <VeryHot spMatches={true}/>
                </Box>
                <MyDivider/>
                <Box id="verybig">
                    <VeryBig spMatches={true}/>
                </Box>
                <MyDivider/>
                {/*過去メニューギャラリー*/}
                <Box id="OldMenuGallery">
                    <OldMenuGallery spMatches={true}/>
                </Box>


            </div>

            <MyDivider/>
            <Box id="bottom">
                <Bottom spMatches={true}/>
            </Box>
            <Copyright spMatches={true}/>
            <Footer/>
        </Container>
    )
}
