import React from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    dividerWrapper: {
        backgroundColor: "rgba(164,143,124, .9)",
        paddingLeft: "5rem",
        paddingRight: "5rem",
        // sp
        [theme.breakpoints.down('sm')]: {
            paddingLeft: "3rem",
            paddingRight: "3rem"
        }
    },
}));
export default function MyDivider () {
    const classes = useStyles();
    return (
        <div className={classes.dividerWrapper}>
            <div style={{borderBottom: "1px solid #444"}}>
            </div>
        </div>
    )
}