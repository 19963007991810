import React from 'react';
import {makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Reveal} from "react-genie";
import gado from "../../assets/gado_chan.png";
import GoogleMap from "./google.maps";
import {
    FacebookIcon,
    FacebookShareButton,
    LineIcon,
    LineShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "rgba(0,0,0, 1)",
        padding: theme.spacing(2)
    },
    snsbox: {
        "& .SocialMediaShareButton": {
            "& div": {
                margin: "0 auto",
            }
        }
    }
}));
const articleUrl = "https://gadogado-suwa.web.app";
const lineShareUrl = "http://line.me/R/msg/text/?" + encodeURIComponent("多国籍バルガドガド " + articleUrl);
export default function Bottom(props) {
    const {spMatches} = props;
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Box mt={spMatches ? 0 : 0} >
                <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={false} sm={false} md={false}/>
                    <Grid item xs={12} md={12}>
                        <Table className={classes.table}>
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={2}
                                        component="td" align="center" className="text-white">
                                        JR上諏訪駅から車で5分<br/>
                                        中央自動車道諏訪ICより車で11分<br/>
                                        上諏訪駅から1,184m
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2}
                                        component="td" align="center" className="text-white">
                                        全席禁煙
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" align="center" className="text-white">
                                        座席数
                                    </TableCell>
                                    <TableCell component="td" align="center" className="text-white">
                                        33席<br/>
                                        (カウンター：5席)
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" align="center" className="text-white">
                                        駐車場
                                    </TableCell>
                                    <TableCell component="td" align="center" className="text-white">
                                        ホワイトモール共用駐車場<br/>をお使いください。
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" align="center" className="text-white">
                                        支払い方法
                                    </TableCell>
                                    <TableCell component="td" align="center" className="text-white">
                                        現金, PayPay, 各種クレジットカード可<br/>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2}
                                               component="td" align="center" className="text-white">
                                        <GoogleMap spMatches={spMatches}/>
                                    </TableCell>
                                </TableRow>
                                {/*<TableRow>*/}
                                {/*    <TableCell colSpan={2}*/}
                                {/*               component="td" align="center" className="text-white">*/}
                                {/*        <Link*/}
                                {/*            href="https://tabelog.com/nagano/A2004/A200404/20018874/"*/}
                                {/*            target="_blank"*/}
                                {/*            className="tabelog-button"*/}
                                {/*        >*/}
                                {/*            <Typography variant="subtitle1"　className="text-bold en" >*/}
                                {/*                <span style={{ marginLeft: '1rem'}}>ガドガドの食べログはこちら。</span>*/}
                                {/*            </Typography>*/}
                                {/*        </Link>*/}
                                {/*    </TableCell>*/}
                                {/*</TableRow>*/}
                                <TableRow>
                                    <TableCell colSpan={2} component="td" align="center" className="text-white">
                                        <Box my={5} textAlign="center" className={classes.snsbox}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={4} sm={4} md={4} elevation={6}>
                                                    <FacebookShareButton url={articleUrl}>
                                                        <FacebookIcon round size={64}/>
                                                        {/*<FacebookIcon round size={matches ? 64 : 96}/>*/}
                                                    </FacebookShareButton>
                                                </Grid>
                                                <Grid item xs={4} sm={4} md={4} elevation={6}>
                                                    <TwitterShareButton url={articleUrl}>
                                                        <TwitterIcon round size={64}/>
                                                        {/*<TwitterIcon round size={matches ? 64 : 96}/>*/}
                                                    </TwitterShareButton>
                                                </Grid>
                                                <Grid item xs={4} sm={4} md={4} elevation={6}>
                                                    <a href={lineShareUrl} target={"_blank"}>
                                                        <LineIcon round size={64} style={{margin: "0 auto"}}/>
                                                        {/*<LineIcon round size={matches ? 64 : 96}/>*/}
                                                    </a>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs={false} sm={false} md={false}/>
                </Grid>
            {/*    google maps */}
            </Box>
            <Reveal delay={200}>
                <img src={gado} className={spMatches ? "gadochan-sp" : "gadochan"}
                     alt="ガドガドのキャラクター" />
            </Reveal>
        </div>
    )
}
