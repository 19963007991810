import Box from "@material-ui/core/Box";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import LazyLoad from "react-lazyload";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const useStyles = makeStyles(theme => ({
    imageBox: {
        width: "100%",
        display: "inline-block",
        position: "relative"
    },
    menuImage: {
        width: "100%",
        height: "150px",
        objectFit: "cover",
        [theme.breakpoints.up('md')]: {
            height: "250px"
        },
    },
    menuPickUpImage: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        [theme.breakpoints.up('md')]: {
            height: "550px"
        },
    },
    flag: {
        position: "absolute",
        top: "0",
        left: "0"
    },
    originFlagPickUp: {
        width: "42px",
        height: "32px",
        objectFit: "cover",
        [theme.breakpoints.up('md')]: {
            width: "85px",
            height: "64px"
        },
    },
    originFlag: {
        width: "42px",
        height: "32px",
        objectFit: "cover",
        [theme.breakpoints.up('md')]: {
            width: "85px",
            height: "64px"
        },
    }
}));


export default function Sp() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const handleClickImage = (props) => {
        setPhotoIndex(props);
        setOpen(true);
    };

    const images = [
        '/images/menu/limited/2023.7.limited.3.jpg',
        '/images/menu/limited/2023.7.limited.1.jpg',
        // '/images/menu/limited/2023.7.limited.2.jpg',
        '/images/menu/limited/2023.3.limited_1.jpg',
        '/images/menu/limited/2023.3.limited_3.jpg',
        '/images/menu/limited/2023.3.limited_4.jpg',
        '/images/menu/limited/2023.3.limited_2.jpg',
    ];

    return (
        <div>
            {open && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + images.length - 1) % images.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % images.length)
                    }
                />
            )}
            <Box display="flex">
                <Grid container spacing={2} >
                    <Grid item xs={12} md={12}>
                        <div className={classes.imageBox}>
                            <LazyLoad>
                                <img src="/images/menu/limited/2023.7.limited.3.jpg"
                                     alt="限定メニュー" className={classes.menuPickUpImage}
                                     onClick={() => {
                                         handleClickImage(0);
                                     }}
                                />
                            </LazyLoad>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}
