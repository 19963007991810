import * as React from "react";
import {Card, CardContent, makeStyles, Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Zoom from '@material-ui/core/Zoom';
import IconButton from "@material-ui/core/IconButton";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {Reveal} from "react-genie";
import Box from "@material-ui/core/Box";
import gomen from "../../../assets/gomen.gif";


const useStyles = makeStyles(theme => ({
    dialogPaper: {
        margin: '0 auto',
    },
    appBar: {
        backgroundColor: "#f14668",
        color: '#fff',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        fontWeight: 'bold',
    },
    content: {
        flex: '1 0 auto',
    },
    MuiDialogContentRoot: {

    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: '#fff',
        // padding: 5px 15px;
    },
    backNumberButton: {
        textAlign: "center",
        display: "block",
        color: "#000",
        margin: "0 auto",
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Zoom ref={ref} {...props} />;
});


export default function OnegaiDialog(props) {
    const classes = useStyles();
    return (
        <>
        <Dialog
            maxWidth='lg'
            classes={{ paper: classes.dialogPaper }}
            TransitionComponent={Transition}
            open={true}
        >
            <MuiDialogTitle className={classes.appBar}>
                <Typography style={{ fontWeight: 'bold' }}
                            component="p"
                            variant="subtitle2">{'おしらせ'}</Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={()=>props.handleOnegaiClose()}>
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <DialogContent>
                <Card>
                    <CardContent>
                        <Box xs={12} mt={1}>
                            <Reveal delay={200}>
                                <Typography component="p" className="" align="left">いつも当店をご利用いただき、ありがとうございます。</Typography>
                            </Reveal>
                        </Box>
                        <Box xs={12} mt={2}>
                            <Reveal delay={1400}>
                                <Typography component="p" mt={2} className="" align="left">現在、原料高騰により、仕入れが不安定になっており、価格が変動しているメニューがございます。</Typography>
                            </Reveal>
                        </Box>
                        <Box xs={12} mt={2}>
                            <Reveal delay={2600}>
                                <Typography component="p" mt={2} className="" align="left">詳しくご確認が必要な場合は、お気軽に当店までお問合せください。</Typography>
                            </Reveal>
                        </Box>
                        <Box xs={12} mt={5}>
                            <Reveal delay={2600} >
                                <img width="100%" src={gomen} alt="ごめんね" />
                            </Reveal>
                        </Box>
                    </CardContent>
                </Card>
            </DialogContent>
        </Dialog>
    </>
)
}
