import Box from "@material-ui/core/Box";
import React, {useState} from "react";
import {Reveal} from "react-genie";
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Lightbox from "react-image-lightbox";
import LazyLoad from "react-lazyload";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "rgba(164,143,124, .9)",
        padding: theme.spacing(8, 0, 6),
    },
    imageBox: {
        width: "100%",
        display: "inline-block",
        position: "relative"
    },
    menuPickUpImage: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        [theme.breakpoints.up('md')]: {
            height: "550px"
        },
    },
    backNumber: {
        color: "#000",
    },
}));
export default function AlaCarte(props) {
    const {spMatches} = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [oldMenuOpen, setOldMenuOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [oldPhotoIndex, setOldPhotoIndex] = useState(0);
    const handleClickImage = (props) => {
        setPhotoIndex(props);
        setOpen(true);
    };
    const handleClickOldMenuOpen = (props) => {
        setOldPhotoIndex(props);
        setOldMenuOpen(true);
    };
    const images = [
        '/images/menu/dinner/1.png',
        '/images/menu/dinner/ala_1.jpg',
        '/images/menu/dinner/ala_2.jpg',
        // '/images/menu/dinner/4.png',
        // '/images/menu/dinner/9.png',
    ];
    return (
        <div className={classes.root}>
            <Reveal>
                {open && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => setOpen(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + images.length - 1) % images.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % images.length)
                        }
                    />
                )}
                <Box fontSize={spMatches ? "h2.fontSize": "h2.fontSize"}>
                    <Typography variant="h3" component="h2" className="text-black text-bold en">
                        ALA CARTE
                    </Typography>
                    <Typography variant={spMatches ? "subtitle1" : "h5"} component="h2" className="text-black text-bold en" >
                        一品料理
                    </Typography>
                </Box>
                <Box mt={1} p={spMatches ? 3 : 10}>
                    <Box display="flex">
                        <Grid container spacing={2} >
                            <Grid item xs={12} md={12}>
                                <div className={classes.imageBox}>
                                    <LazyLoad>
                                        <img src="/images/menu/dinner/1.png"
                                             alt="ALA CARTE" className={classes.menuPickUpImage}
                                             onClick={() => {
                                                 handleClickImage(0);
                                             }}
                                        />
                                    </LazyLoad>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Reveal>
        </div>
    )
}
