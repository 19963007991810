import Box from "@material-ui/core/Box";
import React, {useState} from "react";
import {Reveal} from "react-genie";
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import gado from "../../assets/gado_chan.png";
import Grid from "@material-ui/core/Grid";
import LazyLoad from "react-lazyload";
import Lightbox from "react-image-lightbox";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "rgba(164,143,124, .9)",
        padding: theme.spacing(8, 0, 6),
    },
    imageBox: {
        width: "100%",
        display: "inline-block",
        position: "relative"
    },
    menuPickUpImage: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        [theme.breakpoints.up('md')]: {
            height: "550px"
        },
    },
}));
export default function Lunch(props) {
    const {spMatches} = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const handleClickImage = (props) => {
        setPhotoIndex(props);
        setOpen(true);
    };
    const images = [
        '/images/menu/lunch/1.jpg',
        '/images/menu/lunch/2.jpg',
    ];
    return (
        <div className={classes.root}>
            <Reveal>
                {open && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => setOpen(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + images.length - 1) % images.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % images.length)
                        }
                    />
                )}
                <Box fontSize={spMatches ? "h2.fontSize": "h2.fontSize"}>
                    <Typography variant="h3" component="h2" className="text-black text-bold en">
                        LUNCH
                    </Typography>
                    <Typography variant={spMatches ? "subtitle1" : "h5"} component="h2" className="text-black text-bold en" >
                        ランチメニュー
                    </Typography>
                </Box>
                <Box mt={1} p={spMatches ? 3 : 10}>
                    <Box display="flex">
                        <Grid container spacing={2} >
                            <Grid item xs={12} md={12}>
                                <div className={classes.imageBox}>
                                    <LazyLoad>
                                        <img src="/images/menu/lunch/1.jpg"
                                             alt="ランチ1" className={classes.menuPickUpImage}
                                             onClick={() => {
                                                 handleClickImage(0);
                                             }}
                                        />
                                    </LazyLoad>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                {spMatches && (
                    <Reveal delay={200} className="balloon5">
                        <div className="faceicon">
                            <img src={gado} className={spMatches ? "gadochan-sp" : "gadochan"}
                                 alt="ガドガドのキャラクター" />
                        </div>
                        <div className="chatting">
                            <div className="says">
                                <p>タップするとスライドできるよ</p>
                            </div>
                        </div>
                    </Reveal>
                )}
            </Reveal>
        </div>
    )
}
