import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Top from "../top";
import Copyright from "../copyright";
import React from "react";
import {Reveal} from "react-genie";
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import logo from "../../assets/gadogado_logo.png";
import gado from "../../assets/gado_chan.png";
import Grid from "@material-ui/core/Grid";
import LazyLoad from "react-lazyload";
import origin from "../../assets/origin.jpg";
import FlagIcon from "../FlagIcon";
import Sp from "./sp";

const useStyles = makeStyles(theme => ({
    imageBox: {
        width: "100%",
        height: "80px",
        display: "inline-block",
        position: "relative"
    },
    menuImage: {
        width: "100%",
        height: "150px",
        objectFit: "cover",
        [theme.breakpoints.up('md')]: {
            height: "250px"
        },
    },
    menuPickUpImage: {
        width: "100%",
        height: "200px",
        objectFit: "cover",
        [theme.breakpoints.up('md')]: {
            height: "550px"
        },
    },
    flag: {
        position: "absolute",
        top: "0",
        left: "0"
    },
    originFlagPickUp: {
        width: "42px",
        height: "32px",
        objectFit: "cover",
        [theme.breakpoints.up('md')]: {
            width: "85px",
            height: "64px"
        },
    },
    originFlag: {
        width: "42px",
        height: "32px",
        objectFit: "cover",
        [theme.breakpoints.up('md')]: {
            width: "85px",
            height: "64px"
        },
    }
}));
export default function Pc() {
    const classes = useStyles();
    return (
        <div>
            <Box mt={1} py={10} px={15}>
                <Grid container spacing={2} >
                    <Grid item xs={6} md={6}>
                        <div className={classes.imageBox}>
                            <LazyLoad>
                                <img src="/images/menu/gomadango.jpg"
                                     alt="ゴマ団子" className={classes.menuPickUpImage} />
                                <span className={classes.flag}>
                                     <img src={origin} alt="創作" className={classes.originFlag}/>
                                    {/*<FlagIcon code="jp" size={spMatches ? "2x" : "4x"}/>*/}
                                 </span>
                            </LazyLoad>
                            <Typography component="p" className="text-black text-bold" align="left">
                                ガドガド流ゴマ団子
                            </Typography>
                            <span>
                                <Typography component="p" className="text-black text-bold" align="left">¥380</Typography>
                                <Typography component="p" className="text-black" align="left">
                                    アイス付きは¥420
                                </Typography>
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <div className={classes.imageBox}>
                            <LazyLoad>
                                <img src="/images/menu/tofa.jpg"
                                     alt="花豆" className={classes.menuPickUpImage} />
                                <span className={classes.flag}>
                                    <FlagIcon code="tw" size="3x"/>
                                </span>
                            </LazyLoad>
                            <Typography component="p" className="text-black text-bold" align="left">
                                台湾スイーツ豆花
                            </Typography>
                            <span>
                                <Typography component="p" className="text-black text-bold" align="left">¥380</Typography>
                                <Typography component="p" className="text-black" align="left">
                                   読み方は「トウファ」です。
                                </Typography>
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <div className={classes.imageBox}>
                            <LazyLoad>
                                <img src="/images/menu/konnano_1.jpg"
                                     alt="こんなの" className={classes.menuPickUpImage} />
                            </LazyLoad>
                            <Typography component="p" className="text-black text-bold" align="left">
                                タピオカ？？かな・・・
                            </Typography>
                            <span>
                                <Typography component="p" className="text-black text-bold" align="left">¥???</Typography>
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <div className={classes.imageBox}>
                            <LazyLoad>
                                <img src="/images/menu/konnano_2.jpg"
                                     alt="こんなの" className={classes.menuPickUpImage} />
                            </LazyLoad>
                            <Typography component="p" className="text-black text-bold" align="left">
                                て、店長・・・メニュー名教えてください・・・
                            </Typography>
                            <span>
                                <Typography component="p" className="text-black text-bold" align="left">¥???</Typography>
                            </span>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}
