import {Reveal} from "react-genie";
import React from "react";
import {makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {DISCRIPTIOM_ITEMS, HOT_MENU} from "../const";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import FlagIcon from "../FlagIcon";
import gado from "../../assets/gado_chan.png";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "rgba(164,143,124, .9)",
        padding: theme.spacing(8, 0, 6)
    },
    descriptionTable: {
        backgroundColor: "rgba(164,143,124, 1)",
    },
    originFlag: {
        width: "40px",
        height: "30px",
        objectFit: "cover",
        [theme.breakpoints.up('md')]: {
            width: "55px",
            height: "40px"
        },
    }
}));
export function IconDescription (props) {
    const {spMatches} = props;
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Reveal>
                <Box my={2} fontSize={spMatches ? "h2.fontSize": "h2.fontSize"}>
                    <Typography variant={spMatches ? "subtitle1" : "h5"} component="h2" className="text-black text-bold en" >
                        - 取り扱い料理 -
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Grid container spacing={2} >
                        <Grid item xs={false} sm={false} md={false}/>
                        <Grid item xs={12} md={12}>
                            <Table className={classes.descriptionTable}>
                                <TableBody>
                                    {DISCRIPTIOM_ITEMS.map((listItem, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="center" >
                                                {listItem.image === "" ? (
                                                    <FlagIcon code={listItem.code} size={spMatches ? "2x" : "3x"}/>
                                                ):(
                                                    <img src={`/images/${listItem.image}`} alt={listItem.name} className={classes.originFlag}/>
                                                )}
                                            </TableCell>
                                            <TableCell className="text-black text-bold" align="center" >
                                                {listItem.name}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell className="text-black text-bold" align="center" >
                                            {HOT_MENU}
                                        </TableCell>
                                        <TableCell className="text-black text-bold" align="center" >
                                            辛めのお料理です。<br/>
                                            {HOT_MENU}の数によって辛さが増します。<br/>
                                            （辛くない様にお作りできます。）
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs={false} sm={false} md={false}/>
                    </Grid>
                </Box>
            </Reveal>
            {/*<Reveal delay={200} className="balloon5">*/}
            {/*    <div className="faceicon-right">*/}
            {/*        <img src={gado} className={spMatches ? "gadochan-sp" : "gadochan"}*/}
            {/*             alt="ガドガドのキャラクター" />*/}
            {/*    </div>*/}
            {/*    <div className="chatting">*/}
            {/*        <div className="says-right">*/}
            {/*            <p>シンガポール料理と<br/>フィリピン料理も<br/>たくさんあります🤓</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</Reveal>*/}
        </div>

    )
}
