import * as React from "react";
import {Card, CardContent, Link, makeStyles, Typography} from "@material-ui/core";
// import MenuIcon from "@material-ui/icons/MoreVert";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Zoom from '@material-ui/core/Zoom';
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";


const useStyles = makeStyles(theme => ({
    dialogPaper: {
        margin: '0 auto',
    },
    appBar: {
        backgroundColor: 'rgba(164,143,124, .9)',
        color: '#000',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        fontWeight: 'bold',
    },
    content: {
        flex: '1 0 auto',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: "rgba(0, 0, 0, 1)",
        // padding: 5px 15px;
    },
    backNumberButton: {
        textAlign: "center",
        display: "block",
        color: "#000",
        margin: "0 auto",
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Zoom ref={ref} {...props} />;
});


export default function SelectBackNumberDialog(props) {
    const classes = useStyles();
    function handleClickHistoryOpen (index, page) {
        props.handleShowBackNumber(index, page)
    }
    return (
        <>
        <Dialog
            classes={{ paper: classes.dialogPaper }}
            TransitionComponent={Transition}
            open={true}
        >
            <MuiDialogTitle className={classes.appBar}>
                <Typography style={{ fontWeight: 'bold' }}
                            component="p"
                            variant="subtitle2">{'限定メニューバックナンバー'}</Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={()=>props.handleClose()}>
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <DialogContent>
                <Card>
                    <CardContent className={classes.content}>

                        <Grid spacing={0} alignItems='center' justify='flex-end' container>
                            <Grid item xs={12}>
                                <Link
                                  className={classes.backNumberButton}
                                  onClick={()=> {handleClickHistoryOpen(0, 10)}}
                                >
                                    <LibraryBooksIcon/>
                                    <span style={{ marginLeft: '1rem'}}>{'2024年 お陰様で9周年🎂限定メニュー'}</span>
                                </Link>
                            </Grid>
                        </Grid>
                        <hr/>
                        <Grid spacing={0} alignItems='center' justify='flex-end' container>
                            <Grid item xs={12}>
                                <Link
                                  className={classes.backNumberButton}
                                  onClick={()=> {handleClickHistoryOpen(0, 9)}}
                                >
                                    <LibraryBooksIcon/>
                                    <span style={{ marginLeft: '1rem'}}>{'2024年👹節分!👹限定メニュー'}</span>
                                </Link>
                            </Grid>
                        </Grid>
                        <hr/>

                        <Grid spacing={0} alignItems='center' justify='flex-end' container>
                            <Grid item xs={12}>
                                <Link
                                  className={classes.backNumberButton}
                                  onClick={()=> {handleClickHistoryOpen(0, 8)}}
                                >
                                    <LibraryBooksIcon/>
                                    <span style={{ marginLeft: '1rem'}}>{'2023年末🍱まさかのエスニックおせち'}</span>
                                </Link>
                            </Grid>
                        </Grid>
                        <hr/>
                        <Grid spacing={0} alignItems='center' justify='flex-end' container>
                            <Grid item xs={12}>
                                <Link
                                    className={classes.backNumberButton}
                                    onClick={()=> {handleClickHistoryOpen(0, 7)}}
                                >
                                    <LibraryBooksIcon/>
                                    <span style={{ marginLeft: '1rem'}}>{'2023年🌸春〜夏🏖️の限定メニュー'}</span>
                                </Link>
                            </Grid>
                        </Grid>
                        <hr/>
                        <Grid spacing={0} alignItems='center' justify='flex-end' container>
                            <Grid item xs={12}>
                                <Link
                                    className={classes.backNumberButton}
                                    onClick={()=> {handleClickHistoryOpen(0, 6)}}
                                >
                                    <LibraryBooksIcon/>
                                    <span style={{ marginLeft: '1rem'}}>{'2023年👹節分!👹限定メニュー'}</span>
                                </Link>
                            </Grid>
                        </Grid>
                        <hr/>
                        <Grid spacing={0} alignItems='center' justify='flex-end' container>
                            <Grid item xs={12}>
                                <Link
                                    className={classes.backNumberButton}
                                    onClick={()=> {handleClickHistoryOpen(0, 5)}}
                                >
                                    <LibraryBooksIcon/>
                                    <span style={{ marginLeft: '1rem'}}>{'2022年 🎆夏🎆の限定メニュー'}</span>
                                </Link>
                            </Grid>
                        </Grid>
                        <hr/>
                        <Grid spacing={0} alignItems='center' justify='flex-end' container>
                            <Grid item xs={12}>
                                <Link
                                    className={classes.backNumberButton}
                                    onClick={()=> {handleClickHistoryOpen(0, 4)}}
                                >
                                    <LibraryBooksIcon/>
                                    <span style={{ marginLeft: '1rem'}}>{'2022年 🌸春🌸の限定メニュー！'}</span>
                                </Link>
                            </Grid>
                        </Grid>
                        <hr/>
                        <Grid spacing={0} alignItems='center' justify='flex-end' container>
                            <Grid item xs={12}>
                                <Link
                                    className={classes.backNumberButton}
                                    onClick={()=> {handleClickHistoryOpen(0, 3)}}
                                >
                                    <LibraryBooksIcon/>
                                    <span style={{ marginLeft: '1rem'}}>{'2021年 ⛄冬⛄の限定メニュー!'}</span>
                                </Link>
                            </Grid>
                        </Grid>
                        <hr/>
                        <Grid spacing={0} alignItems='center' justify='flex-end' container>
                            <Grid item xs={12}>
                                <Link
                                    className={classes.backNumberButton}
                                    onClick={()=> {handleClickHistoryOpen(0, 2)}}
                                >
                                    <LibraryBooksIcon/>
                                    <span style={{ marginLeft: '1rem'}}>{'2021年 🍂秋🍂の限定メニュー！'}</span>
                                </Link>
                            </Grid>
                        </Grid>
                        <hr/>

                        <Grid spacing={0} alignItems='center' justify='flex-end' container>
                            <Grid item xs={12}>
                                <Link
                                    className={classes.backNumberButton}
                                    onClick={()=> {handleClickHistoryOpen(0, 1)}}
                                >
                                    <LibraryBooksIcon/>
                                    <span style={{ marginLeft: '1rem'}}>{'2021年 🏖夏🏖の限定メニュー！'}</span>
                                </Link>
                            </Grid>
                        </Grid>
                        <hr/>
                        <Grid spacing={0} alignItems='center' justify='flex-end' container>
                            <Grid item xs={12}>
                                <Link
                                    className={classes.backNumberButton}
                                    onClick={()=> {handleClickHistoryOpen(0, 0)}}
                                    >
                                    <LibraryBooksIcon/>
                                    <span style={{ marginLeft: '1rem'}}>{'2021年 🌸春🌸の限定メニュー！'}</span>
                                </Link>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </DialogContent>
        </Dialog>
    </>
)
}
