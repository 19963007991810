import Box from "@material-ui/core/Box";
import React from "react";
import Grid from "@material-ui/core/Grid";
import {Reveal} from "react-genie";
import gado from "../../assets/gado_chan.png";

export default function GadoThree() {
    return (
        <div>
            <Box display="flex">
                <Grid container spacing={2} >
                    <Grid item xs={4} md={4}>
                        <Reveal delay={200}>
                            <img src={gado} className={"gadochan-sp"}
                                 alt="ガドガドのキャラクター" />
                        </Reveal>
                    </Grid>
                    <Grid item xs={4} md={4}>
                        <Reveal delay={400}>
                            <img src={gado} className={"gadochan-sp"}
                                 alt="ガドガドのキャラクター" />
                        </Reveal>
                    </Grid>
                    <Grid item xs={4} md={4}>
                        <Reveal delay={600}>
                            <img src={gado} className={"gadochan-sp"}
                                 alt="ガドガドのキャラクター" />
                        </Reveal>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}
