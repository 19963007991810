import Box from "@material-ui/core/Box";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import LazyLoad from "react-lazyload";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const useStyles = makeStyles(theme => ({
    imageBox: {
        width: "100%",
        display: "inline-block",
        position: "relative"
    },
    menuImage: {
        width: "100%",
        height: "150px",
        objectFit: "cover",
        [theme.breakpoints.up('md')]: {
            height: "250px"
        },
    },
    menuPickUpImage: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        [theme.breakpoints.up('md')]: {
            height: "550px"
        },
    },
    flag: {
        position: "absolute",
        top: "0",
        left: "0"
    },
    originFlagPickUp: {
        width: "42px",
        height: "32px",
        objectFit: "cover",
        [theme.breakpoints.up('md')]: {
            width: "85px",
            height: "64px"
        },
    },
    originFlag: {
        width: "42px",
        height: "32px",
        objectFit: "cover",
        [theme.breakpoints.up('md')]: {
            width: "85px",
            height: "64px"
        },
    }
}));


export default function BackNumberImage(props, page) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const handleClickImage = (props) => {
        setPhotoIndex(props);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        props.handleClose();
    };

    let images = [
        '/images/menu/limited/2021.3_one_coin.jpg',
        '/images/menu/limited/2021.3_takeout.jpg',
        '/images/menu/limited/2021.3_half.jpg',
        '/images/menu/limited/2021.3_alcohol.jpg',
        '/images/menu/limited/2021.3_non_alcohol.jpg',
    ];

    if (props.backNumberPage === 1) {
        images = [
            '/images/menu/limited/2021.06_summer.jpg',
            '/images/menu/limited/2021.06_takeout.jpg',
        ];
    }
    if (props.backNumberPage === 2) {
        images = [
            '/images/menu/limited/2021.09.box.jpg',
            '/images/menu/limited/2021.09.half.jpg',
            '/images/menu/limited/2021.09.sparerib.jpg',
            '/images/menu/limited/2021.09.takeout.jpg',
        ];
    }
    if (props.backNumberPage === 3) {
        images = [
            '/images/menu/limited/2021.12.takeout.jpg',
            '/images/menu/limited/2021.11.hors.jpg',
            '/images/menu/limited/2021.11.party.jpg',
        ];
    }
    if (props.backNumberPage === 4) {
        images = [
            '/images/menu/limited/2022.3_takeout.jpg',
            '/images/menu/limited/2022.4_7years.jpg',
        ];
    }
    if (props.backNumberPage === 5) {
        images = [
            '/images/menu/limited/2022.6.summer_half.jpg',
            '/images/menu/limited/2022.6.summer_takeout1.jpg',
            '/images/menu/limited/2022.6.summer_takeout2.jpg',
        ];
    }
    if (props.backNumberPage === 6) {
        images = [
            '/images/menu/limited/2023.1.limited_1.jpg',
            '/images/menu/limited/2023.1.limited_2.jpg',
        ];
    }
    if (props.backNumberPage === 7) {
        images = [
            '/images/menu/limited/2023.7.limited.3.jpg',
            '/images/menu/limited/2023.7.limited.1.jpg',
            // '/images/menu/limited/2023.7.limited.2.jpg',
            '/images/menu/limited/2023.3.limited_1.jpg',
            '/images/menu/limited/2023.3.limited_3.jpg',
            '/images/menu/limited/2023.3.limited_4.jpg',
            '/images/menu/limited/2023.3.limited_2.jpg',
        ]
    }
    if (props.backNumberPage === 8) {
        images = [
            '/images/instagram/flyer_osechi.jpg',
            '/images/instagram/flyer_osechi_2.jpg'
        ]
    }
    if (props.backNumberPage === 9) {
        images = [
            '/images/instagram/2024.flyer_ehomaki.jpg',
            '/images/instagram/2024.flyer_ehomaki_2.jpg',
        ]
    }

    if (props.backNumberPage === 10) {
        images = [
            '/images/instagram/9th_anniversary.jpg',
        ]
    }
    return (
        <div>
            <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                onCloseRequest={() => handleClose()}
                onMovePrevRequest={() =>
                    setPhotoIndex((photoIndex + images.length - 1) % images.length)
                }
                onMoveNextRequest={() =>
                    setPhotoIndex((photoIndex + 1) % images.length)
                }
            />
        </div>
    )
}
