import React from 'react';
import './App.css';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {RevealGlobalStyles} from 'react-genie'
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import IndexPage from "./pages/index";

// const Info = lazy(() => import("./component/info"));
// const About = lazy(() => import("./component/about"));
// const Menu = lazy(() => import("./component/menu/index2"));
// const Access = lazy(() => import("./component/access"));
// const Sns = lazy(() => import("./component/sns"));

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 2),
    },
}));
function App() {
    const theme = useTheme();
    const spMatches = useMediaQuery(theme.breakpoints.down('sm'));
    const [mounted, setMounted] = React.useState(true);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        const cleanup = () => {
            setMounted(!mounted);
        };
        return cleanup;
    }, []);

    const classes = useStyles();
    return (
        <div className="App">
            {/*<HelmetDraw />*/}
            <Router>
                <RevealGlobalStyles />
                <Switch>
                    <Route path="/notice_manager">
                        {/*<Box className="row-hero pattern-yellow-white" id="about">*/}
                        {/*    <Admin>*/}
                        {/*        {{*/}
                        {/*            today: today,*/}
                        {/*            todayString: todayString,*/}
                        {/*        }}*/}
                        {/*    </Admin>*/}
                        {/*</Box>*/}
                        {/*<Copyright matches={matches}/>*/}
                    </Route>
                    <Route path="/">
                        <IndexPage spMatches={true}/>
                    </Route>
                </Switch>
            </Router>
        </div>
    );
}

export default App;
