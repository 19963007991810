import Box from "@material-ui/core/Box";
import React, {useState} from "react";
import {Reveal} from "react-genie";
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import gado from "../../assets/gado_chan.png";
import Grid from "@material-ui/core/Grid";
import LazyLoad from "react-lazyload";
import Lightbox from "react-image-lightbox";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "rgba(164,143,124, .9)",
        padding: theme.spacing(8, 0, 6)
    },
    imageBox: {
        width: "100%",
        display: "inline-block",
        position: "relative"
    },
    menuImage: {
        width: "100%",
        height: "150px",
        objectFit: "cover",
        [theme.breakpoints.up('md')]: {
            height: "250px"
        },
    },
    menuPickUpImage: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        [theme.breakpoints.up('md')]: {
            height: "550px"
        },
    },
    flag: {
        position: "absolute",
        top: "0",
        left: "0"
    },
    originFlagPickUp: {
        width: "42px",
        height: "32px",
        objectFit: "cover",
        [theme.breakpoints.up('md')]: {
            width: "85px",
            height: "64px"
        },
    },
    originFlag: {
        width: "42px",
        height: "32px",
        objectFit: "cover",
        [theme.breakpoints.up('md')]: {
            width: "85px",
            height: "64px"
        },
    }
}));

export default function TakeOut(props) {
    const {spMatches} = props;
    const [open, setOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const handleClickImage = (props) => {
        setPhotoIndex(props);
        setOpen(true);
    };
    const images = [
        '/images/menu/takeout/takeout_half.jpg',
        '/images/menu/takeout/takeout_1.jpg',
        '/images/menu/takeout/takeout_2.jpg',
        '/images/menu/takeout/takeout_3.jpg',
        '/images/menu/takeout/takeout_3_2.jpg',
        '/images/menu/takeout/takeout_salad.jpg',
        '/images/menu/takeout/takeout_4.jpg',
        '/images/menu/takeout/takeout_5.jpg',
    ];
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Reveal>
                {open && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => setOpen(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + images.length - 1) % images.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % images.length)
                        }
                    />
                )}
                <Box fontSize={spMatches ? "h2.fontSize": "h2.fontSize"}>
                    <Typography variant="h3" component="h2" className="text-black text-bold en">
                        TAKEOUT
                    </Typography>
                    <Typography variant={spMatches ? "subtitle1" : "h5"} component="h2" className="text-black text-bold en" >
                        テイクアウトメニュー
                    </Typography>
                </Box>
                <Box mt={1} p={spMatches ? 3 : 10}>
                    <Box display="flex">
                        <Grid container spacing={2} >
                            <Grid item xs={12} md={12}>
                                <div className={classes.imageBox}>
                                    <LazyLoad>
                                        <img src="/images/menu/takeout/takeout_half.jpg"
                                             alt="テイクアウト" className={classes.menuPickUpImage}
                                             onClick={() => {
                                                 handleClickImage(0);
                                             }}
                                        />
                                    </LazyLoad>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Reveal>
            <Reveal delay={200} className="balloon5">
                <div className="faceicon">
                    <img src={gado} className={spMatches ? "gadochan-sp" : "gadochan"}
                         alt="ガドガドのキャラクター" />
                </div>
                <div className="chatting">
                    <div className="says">
                        <p>もちろん店内でも食べられます❗</p>
                    </div>
                </div>
            </Reveal>
            <Box className="relativeBox">
                <div className="scroll-prompt"><span></span></div>
            </Box>
        </div>
    )
}
