import Box from "@material-ui/core/Box";
import React, {useState} from "react";
import {Reveal} from "react-genie";
import {Link, makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import HistoryIcon from "@material-ui/icons/History";
import OnegaiDialog from "./modal/modal.onegai";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles(theme => ({
    root: {
        // padding: theme.spacing(8, 0, 6)
    },
    newsContent: {
//        margin: "1rem",
    },
    rootColor2: {
        // backgroundColor: "rgba(0,0,0, .9)",
    },
    backNumberButton: {
        display: "block",
        width: "90%",
        backgroundColor: "#f14668",
        color: "#fff",
        padding: "0.5rem",
        margin: "0 auto",
    },
}));

export default function Onegai(props) {
    const {spMatches} = props;
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    function handleClickOpen () {
        setOpen(true);
    }
    function handleClose () {
        setOpen(false);
    }
    return (
        <div className={classes.root}>
            <Reveal>
                <Box fontSize={spMatches ? "h2.fontSize": "h2.fontSize"} className={classes.rootColor2}>
                    <Box my={2} pb={2} className={classes.rootColor2}>
                        <Link
                            className={classes.backNumberButton}
                            onClick={()=> {handleClickOpen()}}>
                            <Typography variant="subtitle1"　className="text-bold en" >
                                <ErrorOutlineIcon/>
                                <span style={{ marginLeft: '1rem'}}>ガドガドからメニューについてのおしらせ</span>
                            </Typography>
                        </Link>
                        {open && (
                            <OnegaiDialog handleOnegaiClose={handleClose}/>
                        )}
                    </Box>
                </Box>
            </Reveal>
        </div>
    )
}
