import React from 'react';
import StickyFooter from 'react-sticky-footer';
import {makeStyles, useTheme} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import PhoneIcon from "@material-ui/icons/PermPhoneMsg";
import UpIcon from "@material-ui/icons/KeyboardArrowUp";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
    fullWidthLink: {
        display: "inline-block",
        width: "100%"
    }

}))
export default function Footer() {
    const classes = useStyles();
    const linkButton = classNames(classes.fullWidthLink, "text-black");
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    function scrollToTop(scrollDuration = 500) {
        let scrollStep = -window.scrollY / (scrollDuration / 15),
            scrollInterval = setInterval(function(){
                if ( window.scrollY !== 0 ) {
                    window.scrollBy( 0, scrollStep );
                }
                else clearInterval(scrollInterval);
            },15);
    }
    if (matches) {
        return (
            <StickyFooter
                width="100%"
                bottomThreshold={50}
                normalStyles={{
                    backgroundColor: "rgba(255,255,255,1)",
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                    width: '100%'
                }}
                stickyStyles={{
                    backgroundColor: "rgba(255,255,255,1)",
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                    width: '100%'
                }}
            >
                <Box>
                    <Grid container>
                        <Grid item xs={6} className="has-text-centered" style={{borderRight: "1px solid #000"}}>
                            <Link href="tel:0266755324"
                                  className={linkButton} >
                                <PhoneIcon/>
                                <span style={{marginLeft:"1rem"}}></span>
                            </Link>
                        </Grid>
                        <Grid item xs={6} className="has-text-centered">
                            <Link onClick={()=>{scrollToTop()}}
                                  className={linkButton} >
                                <UpIcon/>
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </StickyFooter>
        );
    } else {
        return <div></div>
    }
}
