import * as React from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from "./Marker";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 2),
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },
}));
const lat = 36.041797;
const lng = 138.105127;
const center = {
    lat: lat,
    lng: lng
};
export default function GoogleMap(props) {
    return (
            <div style={{ height: '50vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
                    defaultCenter={center}
                    defaultZoom={16}
                    // onChildClick={(key) => changeBalloon(key)}
                >
                    <Marker
                        lat={lat}
                        lng={lng}
                        name="多国籍バル gadogado"
                        color="#A48F7C"
                        // onChildClick={(key) => changeBalloon(key)}
                    >
                    </Marker>
                </GoogleMapReact>
            </div>
    )
}
